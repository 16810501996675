.user-guide {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .user-guide h1, .user-guide h2 {
    color: #a3a3a3;
  }
  
  .user-guide p, .user-guide ul, .user-guide ol {
    font-size: 16px;
    line-height: 1.6;
    color: #a3a3a3;
  }
  
  .user-guide ul, .user-guide ol {
    padding-left: 20px;
  }
  
  .user-guide h2 {
    margin-top: 20px;
  }
  
  .user-guide a {
    color: #007bff;
    text-decoration: none;
  }
  