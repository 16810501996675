/* Global Styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #282c34;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

/* Dark mode styles */
body.dark-mode {
  background-color: #121212;
  color: #f5f5f5;
}

/* App Header */
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #282c34;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

body.dark-mode .App-header {
  background-color: #1c1f26;
}

/* Custom file input button */
.custom-input {
  display: inline-block;
  padding: 12px 24px;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease-in-out;
}

.custom-input:hover {
  background: linear-gradient(to right, #2575fc, #6a11cb);
}

body.dark-mode .custom-input {
  background: linear-gradient(to right, #524a8d, #405582);
}

body.dark-mode .custom-input:hover {
  background: linear-gradient(to right, #405582, #524a8d);
}

/* Display selected file name */
#fileName {
  margin-left: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #555;
  transition: color 0.3s;
}

body.dark-mode #fileName {
  color: #555;
}

/* Sidebar menu styles */
.sidebar {
  height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1d1f27;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.2);
  padding-top: 20px;
  transition: width 0.3s, background-color 0.3s;
}

.sidebar:hover {
  width: 270px;
}

body.dark-mode .sidebar {
  background-color: #111318;
}

.sidebar h1 {
  padding: 20px;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}

.sidebar a {
  padding: 15px 20px;
  font-size: 18px;
  color: #a8a8a8;
  display: block;
  transition: background 0.3s, color 0.3s;
}

.sidebar a:hover {
  background-color: #282c34;
  color: #ffffff;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #2575fc;
  color: white;
}

body.dark-mode .sidebar a {
  color: #888;
}

body.dark-mode .sidebar a.active {
  background-color: #405582;
}

/* Video Container */
.vidContainer {
  margin-left: 270px;
  padding: 20px;
  transition: margin-left 0.3s;
}

.vidContainer > div {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s, transform 0.3s;
}

body.dark-mode .vidContainer > div {
  background-color: #1e1e1e;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.vidContainer > div.active {
  opacity: 1;
  transform: translateY(0);
}

.fileInput{
  display: none;
}
