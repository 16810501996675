.settings-container {
  padding: 20px;
}

.setting-item {
  margin-bottom: 20px;
}

.setting-item label {
  margin-right: 10px;
}

body.light-mode {
  background-color: #f8f3eb;
  color: #000000;
}

body.dark-mode {
  background-color: #282c34;
  color: #a3a3a3;
}

.settings-container select,
.settings-container input[type="checkbox"] {
  padding: 5px;
  font-size: 1rem;
  color: f5f5f5;
}
